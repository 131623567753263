<template>
  <div class="page-container">
    <div class="container-wide mt-0 pt-4" align="center">
      <b-row>
        <h1 class="title-1 ml-3">{{ $t('climateIndicators') }}</h1>
      </b-row>
      <b-row align-v="center" class="row pt-3 pb-3">
        <div class="col-2">
          <b-form-group
            :label="$t('from')"
            label-for="from"
            label-size="sm"
            content-size="sm"
            class="mb-0"
            align="left"
          >
            <datetime
              id="from"
              v-model="fromDate"
              input-class="form-control form-control-sm"
            ></datetime>
          </b-form-group>
        </div>
        <div class="col-2">
          <b-form-group
            :label="$t('untilAndIncluding')"
            label-size="sm"
            content-size="sm"
            class="mb-0"
            align="left"
            label-for="to"
          >
            <datetime
              id="to"
              v-model="toDate"
              input-class="form-control form-control-sm"
            ></datetime>
          </b-form-group>
        </div>
        <div class="col-2">
          <b-form-group
            :label="`Max. ${$t('co2')}`"
            label-for="maxCo2"
            label-size="sm"
            class="mb-0"
            align="left"
          >
            <b-form-input
              id="maxCo2"
              v-model="maxCo2"
              debounce="500"
              size="sm"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-2">
          <b-form-group
            :label="`Min. ${$t('humidity')}`"
            label-for="minHum"
            label-size="sm"
            class="mb-0"
            align="left"
          >
            <b-form-input
              id="minHum"
              v-model="minHum"
              debounce="500"
              size="sm"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-2">
          <b-form-group
            :label="`Min. ${$t('temperature')}`"
            label-for="minTemp"
            label-size="sm"
            class="mb-0"
            align="left"
          >
            <b-form-input
              id="minTemp"
              v-model="minTemp"
              debounce="500"
              size="sm"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-2">
          <b-form-group
            :label="` Max. ${$t('temperature')}`"
            label-for="maxTemp"
            label-size="sm"
            class="mb-0"
            align="left"
          >
            <b-form-input
              id="maxTemp"
              v-model="maxTemp"
              debounce="500"
              size="sm"
            ></b-form-input>
          </b-form-group>
        </div>
      </b-row>
      <div>
        <b-table
          striped
          hover
          :items="sensors"
          :fields="fields"
          show-empty
        >
          <template #cell(viewHistory)="row">
            <b-button
              class="mt-0 mb-0 pt-0-pb-0"
              variant="primary"
              size="sm"
              @click.stop="viewHistory(row.item.sensor_id)"
              >{{ $t('historicalData') }} ></b-button
            >
          </template>
          <template #cell(perc_with_high_co2)="data"
            >{{ data.value }}%</template
          >
          <template #cell(perc_with_low_hum)="data"
            >{{ data.value }}%</template
          >
          <template #cell(perc_with_low_temp)="data"
            >{{ data.value }}%</template
          >
          <template #cell(perc_with_high_temp)="data"
            >{{ data.value }}%</template
          >
        </b-table>
        <b-button
          v-if="sensors.length > 0"
          class="mt-3"
          @click="csvExport(sensors, `climate-indicators`)"
          >{{ $t('export') }}</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import OkkuApi from '@/services/OkkuApi'
import { Datetime } from 'vue-datetime'
import moment from 'moment-timezone'
import { SET_SENSOR_HISTORY_PARAMS } from '@/store/modules/common/mutationTypes'

export default {
  name: 'ClimateIndicators',
  components: {
    datetime: Datetime
  },
  data() {
    return {
      sensors: [],
      fromDate: moment()
        .startOf('week')
        .add(1, 'days')
        .toISOString(),
      toDate: moment()
        .endOf('week')
        .toISOString(),
      maxCo2: 1000,
      minHum: 30,
      minTemp: 19,
      maxTemp: 26,
      fields: [
        {
          key: 'room',
          label: this.$t('room'),
          sortable: true
        },
        {
          key: 'sensor_id',
          label: this.$t('sensor'),
          sortable: true
        },
        {
          key: 'hours_occupied',
          sortable: true,
          label: this.$t('hoursOccupied')
        },
        {
          key: 'perc_with_high_co2',
          sortable: true,
          label: this.$t('highCo2WhileOccupied'),
          formatter: 'formatPercentage'
        },
        {
          key: 'perc_with_low_hum',
          sortable: true,
          label: this.$t('lowHumidityWhileOccupied'),
          formatter: 'formatPercentage'
        },
        {
          key: 'perc_with_low_temp',
          sortable: true,
          label: this.$t('lowTemperatureWhileOccupied'),
          formatter: 'formatPercentage'
        },
        {
          key: 'perc_with_high_temp',
          sortable: true,
          label: this.$t('highTemperatureWhileOccupied'),
          formatter: 'formatPercentage'
        },
        { key: 'viewHistory', label: '', tdClass: 'col-2' }
      ]
    }
  },
  watch: {
    fromDate() {
      this.getClimateIndicators()
    },
    toDate() {
      this.getClimateIndicators()
    },
    maxCo2() {
      this.getClimateIndicators()
    },
    minHum() {
      this.getClimateIndicators()
    },
    minTemp() {
      this.getClimateIndicators()
    },
    maxTemp() {
      this.getClimateIndicators()
    }
  },
  mounted() {
    const sensorHistoryParams = this.$store.getters[
      'common/getSensorHistoryParams'
    ]
    if (sensorHistoryParams.fromDate)
      this.fromDate = sensorHistoryParams.fromDate
    if (sensorHistoryParams.toDate)
      this.toDate = sensorHistoryParams.toDate
    this.getClimateIndicators()
  },
  methods: {
    home(event) {
      window.location.href = '/workplace'
    },
    formatPercentage(value) {
      return value.toLocaleString(undefined, {
        maximumFractionDigits: 2
      })
    },
    async getClimateIndicators() {
      const sensors = await OkkuApi.getClimateIndicators(
        this.fromDate,
        this.toDate,
        this.maxCo2,
        this.minHum,
        this.minTemp,
        this.maxTemp
      )
      this.sensors = sensors
    },
    viewHistory(sensorId) {
      console.log(`viewHistory ${JSON.stringify(sensorId)}`)
      this.$store.commit(`common/${SET_SENSOR_HISTORY_PARAMS}`, {
        sensorId,
        fromDate: this.fromDate,
        toDate: this.toDate
      })
      this.$router.push({ name: 'SensorHistory' })
    },
    csvExport(arrData, fileName) {
      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += [
        Object.keys(arrData[0]).join(';'),
        ...arrData.map(item => Object.values(item).join(';'))
      ]
        .join('\n')
        .replace(/(^\[)|(\]$)/gm, '')

      const data = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', data)
      link.setAttribute(
        'download',
        `${this.$store.state.common.$organisation}-${fileName}.csv`
      )
      link.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.b-table {
  font-size: smaller;
}
</style>
